<template>

  <van-popup
      round
      v-model:show="shouPopup"
      :style="{ height: '50%' }"
      position="bottom"
      teleport="body"
      closeable
      :close-on-click-overlay="false"
      :lazy-render='true'
      @click-close-icon="this.$emit('update:show', false);"
  >

    <van-form @submit="onConfirm" style="margin-top: 10.66667vw">
      <van-cell-group title="" icon="friends-o" inset>
        <van-field
            v-model="item.id"
            name="id"
            v-show="false"
        ></van-field>
        <van-field
            v-model="item['reserve.id']"
            name="reserve.id"
            v-show="false"
        ></van-field>
        <van-field
            v-model="item.orderCode"
            v-show="false"
            name="orderCode"
            label="订单号"
            :readonly="true"
        />
        <van-field
            v-model="item['material.id']"
            name="material.id"
            label="物料名称"
            :readonly="true"
            placeholder="请选择物料"
            required
            :rules="[{ required: true, message: '请选择物料' }]"
            @click="this.showSelectPicker= true"
        >
          <template #input>
            <div class="van-field__control">{{ getName(item) }}</div>
          </template>
        </van-field>
        <select-material
            v-model:show="showSelectPicker"
            :classify-code="this.isHazardous? '000012': '' "
            @onConfirm="selectMaterial">
        </select-material>

        <van-field
            v-model="item.qty"
            name="qty"
            label="数量"
            type="number"
            clearable
        >
        </van-field>
        <van-field
            v-model="item.orderQty"
            v-show="false"
            name="orderQty"
            label="订单数量"
            :readonly="true"
        />
        <van-field
            v-model="item.unit"
            name="unit"
            label="单位"
            :readonly="true"
        ></van-field>

        <van-cell title="是否为项目/工程物资">
          <van-radio-group v-model="isPRo" direction="horizontal">
            <van-radio name="true">是</van-radio>
            <van-radio name="false">否</van-radio>
          </van-radio-group>
        </van-cell>
        <Picker
            v-if="isPRo==='true'"
            name="principal"
            label="审批人(项目)"
            displayField="nameCode"
            v-model:value="item.principal"
            :displayName="item.principalName"
            :defConfig="{
            portName: 'system.org.User.getUsers',
              data: {'userType.code': 'USER_TYPE_STAFF'},
           }"
            :canSearch=true
            search-param="name"
            auto-search
        ></Picker>
        <van-field
            v-model="item['point.id']"
            name="point.id"
            v-show="false"
        ></van-field>
        <!--        <Picker
                    name="platform.id"
                    label="站台"
                    placeholder="请选择装卸点站台"
                    valueField="platform.id"
                    displayField="platform.name"
                    :required=true
                    v-model:value="item['platform.id']"
                    :defConfig="this.$props.isLoad?
                    {
                    portName: 'osp.park.LoadMaterial.getLoadMaterials',
                      data: {'material.id': item['material.id']}
                    }: {     portName: 'osp.park.UnloadMaterial.getUnloadMaterials',
                      data: {'material.id': item['material.id']}
                    }"
                    :rules="[{ required: true, message: '请选装卸点站台' }]"
                    :canSearch=true
                    @onConfirm="onPlatformConfirm"
                    :onClick="beforePickPlatform"
                ></Picker>
                <van-field
                    v-model="item.pointName"
                    name="PointName"
                    label="装卸点"
                    :readonly="true"
                ></van-field>
                <van-field
                    v-model="item.principalNames"
                    name="principalNames"
                    label="负责人"
                    :readonly="true"
                ></van-field>
                <van-field
                    v-model="item.pointPhone"
                    name="pointPhone"
                    label="手机号"
                    :readonly="true"
                ></van-field>-->
        <!--        <Picker-->
        <!--            name="supplier"-->
        <!--            :label="this.$props.isLoad? '客户':'供应商'"-->
        <!--            :placeholder="this.$props.isLoad? '请选择客户':'请选择供应商'"-->
        <!--            valueField="name"-->
        <!--            displayField="name"-->
        <!--            :required=true-->
        <!--            v-model:value="item.supplier"-->
        <!--            :defConfig="this.$props.isLoad?{-->
        <!--            portName: 'osp.information.Customer.getCustomers',-->
        <!--              data: {},-->
        <!--           }:{-->
        <!--                   portName: 'osp.information.Supplier.getSuppliers',-->
        <!--              data: {},-->
        <!--           }"-->
        <!--            :rules="[{ required: true, message: this.$props.isLoad? '请选择客户':'请选择供应商' }]"-->
        <!--            :canSearch=true-->
        <!--            @onConfirm="onSupplierConfirm"-->
        <!--        ></Picker>-->
        <!--        <van-field-->
        <!--            v-model="item.supplierNo"-->
        <!--            v-show="true"-->
        <!--            name="supplierNo"-->
        <!--            :label="this.$props.isLoad? '客户编号':'供应商编号'"-->
        <!--            :readonly="true"-->
        <!--        ></van-field>-->
        <div style="margin: 4.26667vw;
">
          <van-button round block type="primary" native-type="submit">
            确定
          </van-button>
        </div>
      </van-cell-group>
    </van-form>
  </van-popup>

</template>

<script>
import _ from "lodash";
import Picker from "../../components/Picker";
import SelectMaterial from "./SelectMaterial";
import {Toast} from "vant";

export default {
  name: "EditMaterialPopup",

  components: {SelectMaterial, Picker},

  props: {
    show: {
      default: false,
      type: Boolean
    },
    requestData: {
      default: null,
      type: Object
    },
    isLoad: {
      default: false,
      type: Boolean
    },
    isHazardous: {
      default: false,
      type: Boolean
    },
  },

  emits: ['update:show', 'onConfirm'],

  setup() {
  },

  data() {
    return {
      item: {
        id: '',
        orderCode: '',
        'reserve.id': '',
        'material.id': '',
        'material.name': '',
        qty: '',
        orderQty: 0,
        unit: 'kg',
        principal: '',
        principalName: '',
        'point.id': ''
        // pointName: '',
        // principalNames: '',
        // pointPhone: '',
        // supplier: '',
        // supplierNo: ''
      },
      shouPopup: false,
      showSelectPicker: false,
      isPRo: 'false'
    };
  },

  computed: {},

  watch: {
    show: function (val) {
      this.shouPopup = val;
    },
    isPRo: function (val) {
      if (val === 'true') {
        let option = {
          portName: 'osp.information.material.MaterialLibrary.getMaterialLibrary',
          data: {name: '项目物资'},
          needLoading: false,
          async: false,
          successCallback: (data) => {
            const entity = data.entities[0];
            this.$dialog.confirm({
              title: '注意',
              message: '此为项目物资\n请同业务员确认后慎选择',
            }).then(() => {
              this.item['material.id'] = entity.id;
              this.item['material.name'] = entity.name;
            }).catch(() => {
              this.isPRo = 'false'
            });
          },
          finallyCallback: () => {
          }
        };
        this.$sapi.callPort(option);
      }
    },
  },

  created() {

  },

  mounted() {
  },

  unmounted() {
  },

  methods: {
    onConfirm(value, index) {
      if (_.isEmpty(value)) {
        return;
      }
      this.$emit('update:show', false);
      this.$emit('onConfirm', value);
    },
    selectMaterial(value, index) {
      this.item = {
        id: this.item.id,
        'reserve.id': this.item["reserve.id"],
        'material.id': value.id,
        'material.name': value.name,
        qty: '',
        unit: value.unit,
        'point.id': '',
        // pointName: '',
        // principalNames: '',
        // pointPhone: '',
        // supplier: this.supplier,
        // supplierNo: this.supplierNo
      };
      this.isPRo = value.name === '项目物资' ? 'true' : 'false';
    },
    beforePickPlatform() {
      var id = this.item["material.id"];
      if (_.isEmpty(id)) {
        Toast('请先选择物料');
        return false;
      } else {
        return true
      }
    },
    onPlatformConfirm(value) {
      // var stevedorePoint = value['platform.stevedorePoint.name'];
      // var principalNames = value['principalNames'];
      // var phone = value['platform.stevedorePoint.phone'];
      // this.item.pointName = stevedorePoint;
      // this.item.principalNames = principalNames;
      // this.item.pointPhone = phone;
    },
    getName(item) {
      return _.isEmpty(item['material.name']) ? '请选择物料' : item['material.name']
    },
    // onSupplierConfirm(value) {
    //   this.item.supplierNo = value.code;
    // }
  },
}

</script>

<style scoped>
</style>