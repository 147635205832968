<template>
  <van-popup v-model:show="this.$props.show"
             :style="{ height: '80%' }"
             position="bottom"
             :close-on-click-overlay="false"
             :lazy-render='true'
             teleport="body"
  >
    <div style="text-align: center">点击下方黑色三角号▼切换分类</div>
    <van-dropdown-menu>
      <van-dropdown-item v-model="classID" :options="options" @change="onClassChange"/>
    </van-dropdown-menu>
    <van-search
        label="物料名称"
        v-model="searchText"
        placeholder="搜索"
        show-action
        @search="onSearch"
    >
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <van-picker
        show-toolbar
        :columns="columns"
        :columns-field-names="{ text: 'name'}"
        @confirm="onConfirm"
        @cancel="this.$emit('update:show', false)"
        :default-index="index">
    </van-picker>
  </van-popup>
</template>
<script>
import _ from "lodash";

export default {
  name: 'SelectMaterial',

  components: {},

  props: {
    show: {
      default: false,
      type: Boolean
    },
    requestData: {
      default: null,
      type: Object
    },
    classifyCode: {
      default: '',
      type: String
    }
  },

  emits: ['update:show', 'onConfirm'],

  setup() {
  },

  data() {
    return {
      text: "",
      index: 0,
      searchText: '',
      textValue: '',
      columns: [],
      options: [],
      classID: '',
    };
  },

  computed: {},

  watch: {},

  created() {

  },

  mounted() {
    this.getMaterialClass();
  },

  unmounted() {
  },

  methods: {
    getMaterialClass() {
      var me = this;
      var option = {
        portName: 'osp.information.material.MaterialClassify.getMaterialClassifys',
        data: {code: this.classifyCode},
        needLoading: false,
        successCallback: (data) => {
          var options = me.options;
          me.classID = '';
          options.length = 0;
          if (_.isEmpty(this.classifyCode)) {
            options.push({text: '所有', value: ''});
          }
          _.forEach(data.entities, function (e) {
            options.push({text: e.name, value: e.id});
          });
          if (_.isEmpty(me.classID) && !_.isEmpty(options)) {
            me.classID = options[0].value;
          }
          this.getColumns(me.classID);
        }
      };
      this.$sapi.callPort(option);
    },
    getColumns() {
      var me = this;
      var option = {
        portName: 'osp.information.material.MaterialLibrary.getMaterialLibrarys',
        data: {takeEffect: true},
        needLoading: false,
        pageIndex: 1,
        pageSize: 150,
        successCallback: (data) => {
          var entities = data.entities;
          this.columns = entities;
        }
      };
      if (!_.isEmpty(this.searchText)) {
        option.data['name'] = this.searchText;
      }
      if (!_.isEmpty(this.classID)) {
        option.data['classify.id'] = this.classID;
      }
      this.$sapi.callPort(option);
    },
    onClassChange(value) {
      this.classID = value;
      this.getColumns();
    },
    onSearch() {
      this.getColumns();
    },
    onConfirm(value, index) {
      if (_.isEmpty(value)) {
        return;
      }
      this.$emit('update:show', false);
      this.$emit('onConfirm', value);
    }
  },
}
</script>

<style scoped>

</style>